import { Icons } from '@attendance-web-app/assets/Icons/Icons';
import AvatarName from '@attendance-web-app/components/Common/AvatarName';
import { getFormattedTimeFromDate } from '@attendance-web-app/libs/date';
import { colors } from '@attendance-web-app/theme/colors';
import { ISpendingListTable, ISpendingListTransactionSubBody } from '@attendance-web-app/types/spendingList/type';
import { dateFormatter, formatAmountToStandardFormat, isToday } from '@attendance-web-app/utils';
import { Icon } from '@iconify/react';
import React, { useState } from 'react';
const tableDataClass = `body-small-regular align-middle`;
const SpendingListTransactionSubBody = ({
  vendorName,
  transactionIdNo,
  transactionDate,
  amount,
  remarks,
  isLastIndex
}: ISpendingListTransactionSubBody) => {
  const borderValue = isLastIndex ? '' : 'border-none';
  return (
    <tr>
      <td className={`${borderValue}`}></td>
      <td className={`${borderValue}`}></td>
      <td className={`${borderValue}`}></td>
      <td className={`${borderValue}`}></td>
      <td className={`${tableDataClass} ${borderValue}`}>{vendorName}</td>
      <td className={`${tableDataClass} ${borderValue}`}>{transactionIdNo}</td>
      <td className={`${tableDataClass} ${borderValue}`}>
        {transactionDate && !isToday(transactionDate) && (
          <>
            {dateFormatter(transactionDate, 'YYYY-MM-DD')}
            <br />
          </>
        )}
        {getFormattedTimeFromDate(transactionDate)}
      </td>
      <td className={`${tableDataClass} ${borderValue}`}>Rs. {formatAmountToStandardFormat(parseFloat(amount))}</td>
      <td className={`${tableDataClass} ${borderValue} ellipsisContent`}>
        {remarks.length > 25 ? `${remarks.slice(0, 25)}...` : remarks}
      </td>
    </tr>
  );
};

const SpendingListTable = ({ data, setSelectedSpendingTransations, isLoading }: ISpendingListTable): JSX.Element => {
  const [openRowIndex, setOpenRowIndex] = useState<null | number>(null);

  const handleHeaderCheckboxChange = (event: any) => {
    const isChecked = event.target.checked;
    const spendingTransations = isChecked ? data : [];
    setSelectedSpendingTransations(spendingTransations as any);
    const allRowCheckboxes = document.querySelectorAll('tbody .row-checkbox');
    allRowCheckboxes.forEach(checkbox => {
      (checkbox as HTMLInputElement).checked = isChecked;
    });
  };

  const handleRowCheckboxChange = (event: any, transations: any) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedSpendingTransations(prev => [...prev, transations]);
    } else {
      setSelectedSpendingTransations(prev => prev.filter(data => data !== transations));
    }
  };

  const toggleButtonHandler = (index: number) => {
    if (index === openRowIndex) {
      setOpenRowIndex(null);
    } else {
      setOpenRowIndex(index);
    }
  };
  return (
    <div className="card mt-4">
      <div className="table-responsive log-list-table" style={{ overflowY: 'auto' }}>
        <table className="table border-none">
          <thead className="thead body-regular-semibold attendance-thead">
            <tr>
              <th></th>
              <th className="td">
                {data.length > 0 && <input type="checkbox" name="" id="" onChange={handleHeaderCheckboxChange} />}
              </th>
              <th className="text-uppercase" scope="col">
                Office Id
              </th>
              <th className="text-uppercase" scope="col">
                User Detail
              </th>
              <th className="text-uppercase" scope="col">
                Vendor
              </th>
              <th className="text-uppercase" scope="col">
                Transaction Id
              </th>
              <th className="text-uppercase" scope="col">
                Time
              </th>
              <th className="text-uppercase" scope="col">
                Amount
              </th>
              <th className="text-uppercase" scope="col">
                Remarks
              </th>
            </tr>
          </thead>
          <tbody className="tbody w-100">
            {data?.map((item: any, index: number) => {
              const isRowOpen = openRowIndex === index;
              const borderClass = isRowOpen ? 'border-none' : '';
              const { vendorName, transactionIdNo, transactionDate, amount, remarks } = item?.payment?.[0] ?? {};
              return (
                <React.Fragment key={transactionIdNo}>
                  <tr className="align-items-center" key={transactionIdNo + transactionDate + index}>
                    <td
                      className={`align-middle ${borderClass}`}
                      onClick={() => item?.payment?.length > 1 && toggleButtonHandler(index)}
                    >
                      {item?.payment?.length > 1 && (
                        <Icon
                          icon={`formkit:${isRowOpen ? 'down' : 'right'}`}
                          width="12px"
                          height="12px"
                          color={colors.colorP02}
                        />
                      )}
                    </td>
                    <td className={`align-middle ${borderClass}`}>
                      <input
                        type="checkbox"
                        className="row-checkbox"
                        onChange={e => handleRowCheckboxChange(e, item)}
                      />
                    </td>
                    <td className={`${tableDataClass} ${borderClass}`}>{item?.officialIdNo}</td>

                    <td className={`align-middle ${borderClass}`}>
                      <div className="d-flex align-items-center">
                        {item?.profilePhoto ? (
                          <img
                            src={item.profilePhoto}
                            alt={item.employeeName}
                            width="35px"
                            height="35px"
                            className="round-image"
                          />
                        ) : (
                          <AvatarName name={item.employeeName ?? ''} />
                        )}
                        <div className="ms-4">
                          <p className="body-small-regular color-g02">{item.employeeName}</p>
                          <div className="">
                            <p className="body-small-regular color-g03"> {item.email} </p>
                            {item?.designation && (
                              <p className="body-small-regular color-g03">
                                {item?.designation && <span className="color-g02">{'\u2B22'} </span>}
                                {item?.designation}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </td>

                    <td className={`${tableDataClass} ${borderClass}`}>{vendorName}</td>
                    <td className={`${tableDataClass} ${borderClass}`}>{transactionIdNo}</td>
                    <td className={`${tableDataClass} ${borderClass}`}>
                      {transactionDate && !isToday(transactionDate) && (
                        <>
                          {dateFormatter(transactionDate, 'YYYY-MM-DD')}
                          <br />
                        </>
                      )}
                      {getFormattedTimeFromDate(transactionDate)}
                    </td>
                    <td className={`${tableDataClass} ${borderClass}`}>Rs. {formatAmountToStandardFormat(amount)}</td>
                    <td className={`${tableDataClass} ${borderClass}`}>{remarks}</td>
                  </tr>

                  {item?.payment?.length > 1 &&
                    isRowOpen &&
                    item.payment.map((subItem: ISpendingListTransactionSubBody, subIndex: number) => {
                      return (
                        subIndex > 0 && (
                          <SpendingListTransactionSubBody
                            {...subItem}
                            key={subIndex}
                            isLastIndex={item.payment.length - 1 === subIndex}
                          />
                        )
                      );
                    })}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
        {isLoading ? (
          <div className="flex-1 d-flex justify-content-center align-items-center w-100">
            <Icons.Loading height={20} width={20} />
          </div>
        ) : (
          data.length <= 0 && (
            <div className="d-flex flex-column align-items-center">
              <span>
                <Icons.emptyTransaction height={65} width={145} />
              </span>
              <span>No Transaction to show</span>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default SpendingListTable;

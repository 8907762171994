import { SpendingListAction, SpendingListActionEnum } from '../actions/spendingListAction.d';
import { spendingListReducersProps } from './spendingListReducer.d';
export const spendingListInitialState: spendingListReducersProps = {
  data: [],
  selectedSpendingList: [],
  spendingList: [],
  totalCount: 0,
  totalPages: null,
  departmentSelectedOption: {
    selected: '',
    value: []
  },
  designationSelectedOption: {
    selected: '',
    value: []
  },
  vendorsSelectedOption: {
    selected: '',
    value: []
  },
  departmentTypes: [],
  selectedEndDate: '',
  selectedStartDate: '',
  paramsValue: {
    date: '',
    searchText: '',
    limit: 10,
    page: 1,
    departments: '',
    designations: '',
    vendors: ''
  }
};
export const spendingListReducer = (
  state: spendingListReducersProps,
  action: SpendingListAction
): spendingListReducersProps => {
  switch (action.type) {
    case SpendingListActionEnum.SAVE_SPENDING_LIST_DATA:
      return {
        ...state,
        ...action.payload
      };

    case SpendingListActionEnum.SAVE_SPENDING_LIST_PARAMS_DATA:
      return {
        ...state,
        paramsValue: {
          ...state.paramsValue,
          ...action.payload
        }
      };
    default:
      return state;
  }
};

import CustomCalendar from '@attendance-web-app/components/Common/CustomCalender';
import CustomFilterChip, { CustomFilterChipTrigger } from '@attendance-web-app/components/Common/CustomFilterChip';
import CustomPagination from '@attendance-web-app/components/Common/CustomPagination';
import MultiSelectDropDownContainer from '@attendance-web-app/components/Common/MultiSelectDropDownContainer';
import ScreenHeader from '@attendance-web-app/components/Common/ScreenHeader';
import DownloadSpendingViewCsv from '@attendance-web-app/components/_pages/_Dashboard/DownloadSpendingViewCsv';
import PageWrapper from '@attendance-web-app/components/_pages/_PageWrapper';
import SpendingAdvanceFilter from '@attendance-web-app/components/_pages/_SpendingList/SpendingAdanceFilter';
import SpendingListTable from '@attendance-web-app/components/_pages/_SpendingList/SpendingListTable';
import SpendingViewCard from '@attendance-web-app/components/_pages/_SpendingList/SpendingViewCard';
import CustomSearchBar from '@attendance-web-app/components/_pages/_header/LogSearchBar';
import { selectedOptionType } from '@attendance-web-app/types';
import { useSpendingListPageHook } from './hooks/useSpendingListPage';

const SpendingList = () => {
  const {
    spendingViewCardsOutput,
    actualShowAdvancedFilterHandler,
    onChangeSelectHandler,
    showAdvancedFilter,
    onApplyHandler,
    selectedOptions,
    departmentTypes,
    vendors,
    cancelAdvancedFilter,
    handleLimitChange,
    designationTypes,
    spendingList,
    handlePaginateClick,
    selectedSpendingTransactions,
    setSelectedSpendingTransactions,
    onChangeSearchHandler,
    spendingListState,
    onResetHandler,
    onChipChangeSelectHandler,
    onClearAllFilter,
    departmentSelectedOptionValue,
    designationSelectedOptionValue,
    vendorsSelectedOptionValue,
    spendingListLoading,
    openAdvanceFilter
  } = useSpendingListPageHook();

  return (
    <PageWrapper
      hasFooter={true}
      footerComponent={
        <CustomPagination
          totalCount={spendingList?.total ?? 0}
          totalPages={spendingList?.totalPages}
          handleLimitChange={handleLimitChange}
          handlePaginateClick={handlePaginateClick}
          page={spendingListState.paramsValue?.page}
          isPage={true}
          limit={spendingListState.paramsValue?.limit}
          offset={10}
        />
      }
    >
      <section className={`spendingView ${spendingList?.total > 10 ? 'marginBottom' : ''}`}>
        {/* Header */}
        <ScreenHeader title="Spending View">
          <div className="d-flex gap-2 flex-1 flex-wrap justify-content-end">
            <CustomSearchBar
              openAdvanceFilter={openAdvanceFilter}
              toggleAdvancedFilter={actualShowAdvancedFilterHandler}
              onChangeSearchHandler={onChangeSearchHandler}
            />
            <CustomCalendar
              onSelectedDate={onChangeSelectHandler}
              showLabel={false}
              disabled={spendingListState?.selectedStartDate !== '' && spendingListState?.selectedEndDate !== ''}
            />
            <DownloadSpendingViewCsv
              selectedSpendingTransactions={selectedSpendingTransactions}
              disabled={selectedSpendingTransactions.length === 0}
            />
          </div>
        </ScreenHeader>

        {/* Cards */}
        {departmentSelectedOptionValue.length === 0 &&
          designationSelectedOptionValue.length === 0 &&
          vendorsSelectedOptionValue.length === 0 &&
          spendingListState?.selectedStartDate === '' &&
          spendingListState?.selectedEndDate === '' && (
            <SpendingViewCard spendingViewCardsOutput={spendingViewCardsOutput} />
          )}
        {/* Filter Components */}
        <div
          className={`customFilterChipContainer ${
            departmentSelectedOptionValue.length > 0 ||
            designationSelectedOptionValue.length > 0 ||
            vendorsSelectedOptionValue?.length > 0
              ? 'my-4 '
              : 'mt-2'
          }`}
        >
          {departmentSelectedOptionValue.length > 0 && (
            <CustomFilterChip
              title="Department"
              value={departmentSelectedOptionValue}
              onCrossClick={() => {
                onResetHandler(selectedOptionType.DEPARTMENT_TYPE);
              }}
              length={departmentSelectedOptionValue.length}
            >
              <MultiSelectDropDownContainer
                isVisible={true}
                onEmployeeTypeResetHandler={() => {}}
                options={spendingListState.departmentTypes}
                selectedOptions={departmentSelectedOptionValue}
                selectOptionType={selectedOptionType.DEPARTMENT_TYPE}
                onChangeSelectHandler={onChipChangeSelectHandler}
              />
            </CustomFilterChip>
          )}
          {designationSelectedOptionValue.length > 0 && (
            <CustomFilterChip
              title="Designation"
              value={designationSelectedOptionValue}
              onCrossClick={() => {
                onResetHandler(selectedOptionType.DESIGNATION_TYPE);
              }}
              length={designationSelectedOptionValue.length}
            >
              <MultiSelectDropDownContainer
                isVisible={true}
                onEmployeeTypeResetHandler={() => {}}
                options={spendingListState.designationTypes}
                selectedOptions={designationSelectedOptionValue}
                selectOptionType={selectedOptionType.DESIGNATION_TYPE}
                onChangeSelectHandler={onChipChangeSelectHandler}
              />
            </CustomFilterChip>
          )}
          {vendorsSelectedOptionValue.length > 0 && (
            <CustomFilterChip
              title="Vendors"
              value={vendorsSelectedOptionValue}
              onCrossClick={() => {
                onResetHandler(selectedOptionType.VENDORS);
              }}
              length={vendorsSelectedOptionValue.length}
            >
              <MultiSelectDropDownContainer
                isVisible={true}
                onEmployeeTypeResetHandler={() => {}}
                options={spendingListState.vendors}
                selectedOptions={vendorsSelectedOptionValue}
                selectOptionType={selectedOptionType.VENDORS}
                onChangeSelectHandler={onChipChangeSelectHandler}
              />
            </CustomFilterChip>
          )}
          {spendingListState?.selectedStartDate && (
            <CustomFilterChip
              title="Selected Start Date"
              value={spendingListState.paramsValue?.startDate}
              isDate
              onCrossClick={() => {
                onResetHandler(selectedOptionType?.START_DATE);
              }}
            >
              <CustomCalendar
                onSelectedDate={onChipChangeSelectHandler}
                defaultDate={spendingListState?.selectedStartDate || ''}
                label="Select Start Date"
                customDatePickerTrigger={
                  <CustomFilterChipTrigger
                    title={'Selected Start Date '}
                    value={spendingListState.paramsValue?.startDate}
                    onCrossClick={() => {
                      onResetHandler(selectedOptionType?.START_DATE);
                    }}
                  />
                }
              />
            </CustomFilterChip>
          )}
          {spendingListState?.selectedEndDate && (
            <CustomFilterChip
              title="Selected End Date"
              value={spendingListState.paramsValue?.endDate}
              isDate
              onCrossClick={() => {
                onResetHandler(selectedOptionType?.START_DATE);
              }}
            >
              <CustomCalendar
                onSelectedDate={onChipChangeSelectHandler}
                label="Select End Date"
                defaultDate={spendingListState?.selectedEndDate || ''}
                hasMinDate
                minDate={new Date(spendingListState?.paramsValue?.startDate as string)}
                customDatePickerTrigger={
                  <CustomFilterChipTrigger
                    title={'Selected End Date '}
                    value={spendingListState.paramsValue?.endDate}
                    onCrossClick={() => {
                      onResetHandler(selectedOptionType?.START_DATE);
                    }}
                  />
                }
              />
            </CustomFilterChip>
          )}
          {(departmentSelectedOptionValue.length ?? 0 > 0) ||
          (designationSelectedOptionValue.length ?? 0 > 0) ||
          (vendorsSelectedOptionValue.length ?? 0 > 0) ? (
            <div className="customFilterChipContainer__clearAllButton">
              <span className="body-regular-semibold colors-p02" onClick={onClearAllFilter}>
                Clear all
              </span>
            </div>
          ) : null}
        </div>
        {/* Table */}
        <SpendingListTable
          isLoading={spendingListLoading}
          data={spendingList?.transactions ?? []}
          setSelectedSpendingTransations={setSelectedSpendingTransactions}
        />
      </section>

      {showAdvancedFilter && (
        <SpendingAdvanceFilter
          openAdvanceFilter={openAdvanceFilter}
          showAdvancedFilter={showAdvancedFilter}
          onApplyHandler={onApplyHandler}
          onChangeSelectHandler={onChangeSelectHandler}
          selectedDepartmentTypes={selectedOptions.departmentSelectedOption}
          selectedDesignationTypes={selectedOptions.designationSelectedOption}
          departmentTypes={departmentTypes}
          designationTypes={designationTypes}
          vendors={vendors}
          cancelAdvancedFilter={cancelAdvancedFilter}
          selectedVendorTypes={selectedOptions.vendorsSelectedOption}
          startDate={selectedOptions.startDate}
          endDate={selectedOptions.endDate}
        />
      )}
    </PageWrapper>
  );
};

export default SpendingList;

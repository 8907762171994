import { Icons } from '@attendance-web-app/assets/Icons/Icons';
import { colors } from '@attendance-web-app/theme/colors';
import { ISearchProps } from '@attendance-web-app/types/logs/type';
import React, { useEffect, useState } from 'react';

const CustomSearchBar = ({
  toggleAdvancedFilter,
  onChangeSearchHandler,
  hasIcon = true,
  hasInputBox = true
}: ISearchProps): JSX.Element => {
  const [search, setSearch] = useState('');
  const mainSearchHandler = (input: string) => {
    onChangeSearchHandler && onChangeSearchHandler(input);
  };
  const handleClearInput = () => {
    setSearch('');
    mainSearchHandler('');
  };
  useEffect(() => {
    mainSearchHandler('');
  }, []);

  useEffect(() => {
    if (search) {
      const timeoutHandler = setTimeout(() => {
        mainSearchHandler(search);
      }, 500);
      return () => {
        clearTimeout(timeoutHandler);
      };
    }
  }, [search]);
  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    if (event.target.value === '') {
      mainSearchHandler(event.target.value);
    }
  };

  return (
    <form onSubmit={e => e.preventDefault()}>
      <div className={`header-search-bar ${!hasInputBox ? 'withNoInput' : ''}`}>
        <div className="search-bar" onClick={hasInputBox ? () => {} : toggleAdvancedFilter}>
          {hasInputBox ? (
            <div className="custom-search">
              <Icons.SearchIcon height={20} fill="#595959" className="search-icon" />
              <input
                className="body-large-regular"
                type="text"
                placeholder="Search"
                value={search}
                onChange={onSearchChange}
              />
              {search && (
                <Icons.CrossIcon height={20} fill="#595959" className="clear-icon" onClick={handleClearInput} />
              )}
            </div>
          ) : (
            <span className="search-bar-filter-label body-regular">Filter</span>
          )}
          {hasIcon && (
            <div className="searchFilter-icon">
              <Icons.AdvanceFilter height={20} width={20} fill={colors.colorG02} onClick={toggleAdvancedFilter} />
            </div>
          )}
        </div>
      </div>
    </form>
  );
};

export default CustomSearchBar;

import CustomDropDownSelect from '@attendance-web-app/components/Common/CustomDropDownSelect';
import CustomModal from '@attendance-web-app/components/Common/CustomModal';
import CustomMultiDropDownSelect from '@attendance-web-app/components/Common/CustomMultiDropDownSelect';
import { useEmployeeContext } from '@attendance-web-app/global-state/context/EmployeeContext';
import { Option } from '@attendance-web-app/global-state/reducers/employeeReducer.d';
import { statusTypes } from '@attendance-web-app/pages/employeeList/hooks/useEmployeeScreenHook';
import { selectedOptionType } from '@attendance-web-app/types';
import { shiftArr } from '@attendance-web-app/utils';
import React from 'react';

interface AdvanceEmployeeFilterSidebarProps {
  onApplyHandler: () => void;
  onChangeSelectHandler: (type: string, options: any) => void;
  cancelAdvancedFilter: () => void;
  showAdvanceFilter?: boolean;
  openAdvanceFilter?: () => void;

  // showing list on dropdown
  departmentTypes?: Option[];
  designationTypes?: Option[];
  hodTypes?: Option[];
  supervisorsTypes?: Option[];

  roleTypes?: Option[];

  // selected options
  selectedDepartmentTypes: Option[];
  selectedDesignationTypes: Option[];
  selectedHodTypes: Option[];
  selectedSupervisorsTypes: Option[];
  selectedStatusTypes: Option[];
  selectedRoleTypes: Option[];
  selectedShiftTypes: Option;
}

const AdvanceEmployeeFilterSidebar: React.FC<AdvanceEmployeeFilterSidebarProps> = ({
  onApplyHandler,
  onChangeSelectHandler,
  selectedDepartmentTypes,
  departmentTypes,
  selectedDesignationTypes,
  hodTypes,
  selectedHodTypes,
  selectedSupervisorsTypes,
  roleTypes,
  selectedRoleTypes,
  selectedStatusTypes,
  cancelAdvancedFilter,
  showAdvanceFilter,
  openAdvanceFilter,
  selectedShiftTypes
}) => {
  const hideSidebar = () => {
    cancelAdvancedFilter();
  };
  const onApplyMainHandler = () => {
    cancelAdvancedFilter();
    onApplyHandler();
  };

  const { employeeState } = useEmployeeContext();
  return (
    <CustomModal
      showModal={showAdvanceFilter}
      handleMenuOpen={openAdvanceFilter}
      handleHideModal={hideSidebar}
      modalTitle="Advance Filter"
      footerComponent={
        <>
          <button className="btn-tertiary" onClick={hideSidebar}>
            Cancel
          </button>
          <button
            className="btn "
            onClick={onApplyMainHandler}
            disabled={
              selectedDepartmentTypes.length <= 0 &&
              selectedDesignationTypes.length <= 0 &&
              selectedHodTypes.length <= 0 &&
              selectedRoleTypes.length <= 0 &&
              selectedSupervisorsTypes.length <= 0 &&
              selectedShiftTypes?.value?.length <= 0 &&
              selectedStatusTypes?.length <= 0
            }
          >
            Apply Filter
          </button>
        </>
      }
    >
      <div className="advance-filter-content">
        <div className="search-content w-100">
          <CustomMultiDropDownSelect
            key="Select Department"
            title="Select Department"
            options={departmentTypes}
            selectedOptions={selectedDepartmentTypes}
            onChangeSelectHandler={onChangeSelectHandler}
          />
          <CustomMultiDropDownSelect
            title="Select Designation"
            options={employeeState?.designationTypes}
            selectedOptions={selectedDesignationTypes}
            onChangeSelectHandler={onChangeSelectHandler}
            isEditable={employeeState?.designationTypes?.length ? true : false}
          />
          <div className="flex-option">
            <CustomMultiDropDownSelect
              key="Select HOD"
              title="Select HOD"
              options={hodTypes}
              selectedOptions={selectedHodTypes}
              onChangeSelectHandler={onChangeSelectHandler}
            />
            <CustomMultiDropDownSelect
              title="Select Supervisor"
              options={employeeState?.superVisorTypes ?? []}
              selectedOptions={selectedSupervisorsTypes}
              onChangeSelectHandler={onChangeSelectHandler}
              isEditable={employeeState?.superVisorTypes?.length ? true : false}
            />
          </div>
          <div className="flex-option">
            <CustomMultiDropDownSelect
              key="Select Role"
              title="Select Role"
              options={roleTypes}
              selectedOptions={selectedRoleTypes}
              onChangeSelectHandler={onChangeSelectHandler}
            />
            <CustomMultiDropDownSelect
              title="Select Status"
              key="Select Status"
              options={statusTypes}
              selectedOptions={selectedStatusTypes}
              onChangeSelectHandler={onChangeSelectHandler}
            />
          </div>
          <CustomDropDownSelect
            title="Select Shift"
            options={shiftArr.map((shift, index) => {
              return {
                ...shift,
                id: index
              };
            })}
            selectedOptions={selectedShiftTypes}
            onChangeSelectHandler={options => onChangeSelectHandler(selectedOptionType.SHIFT, options)}
          />
        </div>
      </div>
    </CustomModal>
  );
};

export default AdvanceEmployeeFilterSidebar;

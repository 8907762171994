import { Icons } from '@attendance-web-app/assets/Icons/Icons';
import { months } from '@fuse-apps/shared-constants';
import { Icon } from '@iconify/react';
import { format } from 'date-fns';
import DatePicker from 'react-datepicker';
import { useCustomCalendar } from './hooks/useCustomCalendar';

type selectedDate = Date;
interface ICustomCalendar {
  onSelectedDate: (type: string, date: selectedDate) => void;
  customDatePickerTrigger?: React.ReactNode;
  showLabel?: boolean;
  hasCalenderIcon?: boolean;
  isAdvancedFilter?: boolean;
  period?: string;
  defaultDate?: Date | string;
  label?: string;
  customClassName?: string;
  hasMinDate?: boolean;
  minDate?: Date;
  disabled?: boolean;
}

const currentYear = new Date().getFullYear();

const CustomCalendar = ({
  hasCalenderIcon = false,
  onSelectedDate,
  customDatePickerTrigger,
  showLabel = true,
  hasMinDate = false,
  minDate = undefined,
  isAdvancedFilter = false,
  period = '',
  defaultDate = new Date(),
  label = 'Select Date',
  customClassName = '',
  disabled = false
}: ICustomCalendar) => {
  const {
    year,
    setYear,
    years,
    month,
    setMonth,
    startDate,
    onDateChangeHandler,
    handleCalendarOpen,
    setIsOpen,
    renderDayContents,
    isOpen
  } = useCustomCalendar(onSelectedDate, period, defaultDate, label);

  return (
    <div className={`customCalender ${customClassName}`}>
      <DatePicker
        disabled={disabled}
        renderCustomHeader={({ changeYear, changeMonth }) => (
          <div className="header">
            <select
              className="body-regular"
              value={year}
              onChange={({ target: { value } }) => {
                setYear(Number(value));
                changeYear(Number(value));
              }}
            >
              {years.map(option => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            <select
              className="body-regular"
              value={month}
              onChange={({ target: { value } }) => {
                setMonth(value);
                changeMonth(months.indexOf(value));
              }}
            >
              {months.map(option => (
                <option key={option} value={option}>
                  {option.toUpperCase()}
                </option>
              ))}
            </select>
          </div>
        )}
        selected={startDate}
        onChange={onDateChangeHandler as any}
        useWeekdaysShort
        customInput={
          customDatePickerTrigger ? (
            <div onClickCapture={handleCalendarOpen}>{customDatePickerTrigger}</div>
          ) : (
            <button
              className={`calendar-input ${isAdvancedFilter ? 'advancedFilter' : ''} body-large-regular w-100 ${
                disabled ? 'disabled' : ''
              }`}
              onClickCapture={handleCalendarOpen}
            >
              {showLabel && (
                <label className={`log-select-label invite-employee-select `} id="log-selected-dropdown">
                  <span className="select-text body-small-regular px-1 color-g02">{label}</span>
                </label>
              )}

              <div className="calendar-input-label">
                <span className="ml-2">{startDate ? format(startDate, 'dd MMM, yyyy') : null}</span>
                <span className="mr-2 pl-2">
                  {isOpen ? (
                    hasCalenderIcon ? (
                      <Icons.CalendarIcon height={16} width={16} />
                    ) : (
                      <Icon icon="icon-park:up" height={16} width={16} color="#595959" />
                    )
                  ) : hasCalenderIcon ? (
                    <Icons.CalendarIcon icon="icon-park:down" height={16} width={16} />
                  ) : (
                    <Icon icon="icon-park:down" height={16} width={16} color="#595959" />
                  )}
                </span>
              </div>
            </button>
          )
        }
        onCalendarClose={() => setIsOpen(false)}
        minDate={hasMinDate ? minDate : undefined}
        maxDate={new Date()}
        renderDayContents={renderDayContents}
      />
    </div>
  );
};
export default CustomCalendar;

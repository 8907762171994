import { IAttendance } from '@attendance-web-app/global-state/reducers/attendanceReducer.d';
import { selectedOptionType } from '@attendance-web-app/types';
import isBetween from 'dayjs/plugin/isBetween';
const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);
dayjs.extend(isBetween);
export const shiftArr = [
  {
    label: 'Day',
    value: 'day'
  },
  {
    label: 'Evening',
    value: 'evening'
  },
  {
    label: 'Night',
    value: 'night'
  }
];
export const getTimePeriod = (timePeriod?: string) => {
  switch (timePeriod) {
    case 'week':
      return 'Weekly';
    case 'month':
      return 'Monthly';
    default:
      return 'Daily';
  }
};

export const getSelectOptionTypeEnumFromTitle = (title: string) => {
  switch (title) {
    case 'Select Type':
      return selectedOptionType.LOG_TYPE;
    case 'Select Department':
      return selectedOptionType.DEPARTMENT_TYPE;
    case 'Select Designation':
      return selectedOptionType.DESIGNATION_TYPE;
    case 'Select HOD':
      return selectedOptionType.HOD_TYPE;
    case 'Select Supervisor':
      return selectedOptionType.SUPERVISOR_TYPE;
    case 'Select Role':
      return selectedOptionType.ROLE_TYPE;
    case 'Select Status':
      return selectedOptionType.STATUS_TYPE;
    case 'Select Bank':
      return selectedOptionType.BANK;
    case 'Select Vendor':
      return selectedOptionType.VENDORS;
    case 'Select Shift':
      return selectedOptionType.SHIFT;
    case 'Select Drop Off Option':
      return selectedOptionType.DROP_OFF_SERVICE;
    default:
      return '';
  }
};

export const joinArrayWithComma = (inputArr: any[], inputKey?: any) => {
  if (inputArr.length > 0) {
    return inputArr
      ?.map((item: any) => {
        return inputKey ? item?.[inputKey] : item;
      })
      .join(',');
  }
  return '';
};

export const returnDropdownOption = (data: any[], { label = '', value = '' }): { label: string; value: string }[] => {
  if (data.length > 0) {
    const dropDowns = data.map(item => {
      return {
        label: label ? item[label] : item,
        value: value ? item[value] : item
      };
    });

    return dropDowns;
  }
  return [];
};
export function findArrayDifference(arr1: any[], arr2: any[], objectKey: string) {
  const arrDifference = arr2.filter(item2 => !arr1.some(item1 => item1[objectKey] === item2[objectKey]));
  return arrDifference;
}

export const returnArrayOfStringFromObjectKey = (arr: any[], objectKey: string) => {
  return arr.map((option: any) => option[objectKey]) ?? [];
};

export const returnArrayOfObjectIfValueMatchesFromArrayOfString = (
  filterTo?: any[],
  filterFrom?: any[],
  objectKey?: string
) => {
  return (
    (filterTo?.filter((filterToObject: any) => filterFrom?.includes(filterToObject?.[objectKey ?? ''])) as any) ?? []
  );
};
export const returnArrayOfObjectIfValueNotMatchesFromArrayOfString = (
  filterTo?: any[],
  filterFrom?: any[],
  objectKey?: string
) => {
  return (
    (filterTo?.filter((filterToObject: any) => !filterFrom?.includes(filterToObject?.[objectKey ?? ''])) as any) ?? []
  );
};

export const prepareAttendanceToDownload = (attendanceData?: IAttendance[]) => {
  const attendanceDataToDownload = attendanceData?.map((data: any) => {
    return {
      EmployeeID: data.employee.employeeIdNo,
      Name: data.employee.name,
      Email: data.employee.email,
      Department: data.employee.department,
      Date: data.date,
      CheckInTime: data.checkInConverted,
      CheckOutTime: data.checkOutConverted,
      Supervisor: data.employee?.supervisor?.name,
      Hod: data.employee?.HOD?.name
    };
  });
  return attendanceDataToDownload;
};
export const prepareSpendingListToDownload = (attendanceData?: any[]) => {
  const attendanceDataToDownload = attendanceData?.map((data: any) => {
    return {
      EmployeeID: data.employee.employeeIdNo,
      Name: data.employee.name,
      Email: data.employee.email,
      Department: data.employee.department,
      Date: data.date,
      CheckInTime: data.checkInConverted,
      CheckOutTime: data.checkOutConverted,
      Supervisor: data.employee?.supervisor?.name,
      Hod: data.employee?.HOD?.name
    };
  });
  return attendanceDataToDownload;
};
export const formatFileSize = (bytes: number) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};
export const returnProperName = (name: string) => {
  return name ? name : '';
};
export const combineFirstNameAndLastName = (firstName: string, lastName: string) => {
  return `${returnProperName(firstName)} ${returnProperName(lastName)}` || '';
};
export function formatAmountToStandardFormat(amount: number) {
  if (amount) {
    const absoluteAmount = Math.abs(amount);
    const formatter = new Intl.NumberFormat('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
    return ` ${formatter.format(absoluteAmount)}`;
  }
  return ' 0.00';
}
export enum DropServiceOptions {
  USE_PATHAO = 'USE_PATHAO',
  USE_OWN_VEHICLE = 'USE_OWN_VEHICLE',
  WILL_NOT_USE = 'WILL_NOT_USE'
}

export const getDropOffServiceOption = (option: DropServiceOptions): string => {
  switch (option) {
    case DropServiceOptions.USE_PATHAO:
      return "Yes , I'll use it ";
    case DropServiceOptions.USE_OWN_VEHICLE:
      return 'I’ll be using my own vehicle';
    case DropServiceOptions.WILL_NOT_USE:
      return "No, I won't";
    default:
      throw new Error('Invalid drop-off service option.');
  }
};
export const dropOptionsArr: {
  label: string;
  value: DropServiceOptions;
}[] = [
  {
    label: "Yes , I'll use it",
    value: DropServiceOptions.USE_PATHAO
  },
  {
    label: 'I’ll be using my own vehicle',
    value: DropServiceOptions.USE_OWN_VEHICLE
  },
  {
    label: "No, I won't",
    value: DropServiceOptions.WILL_NOT_USE
  }
];
export const isAdminUser = () => {
  const adminUser =
    localStorage.getItem('admin_user') !== 'undefined'
      ? JSON.parse(localStorage.getItem('admin_user') as string)
      : null;

  return adminUser?.role?.role === 'superadmin';
};
export const isDepartNestedDepartmentName = (name?: string) => {
  if (name && name.includes(`/`)) {
    const splitDepartmentName = name.split('/');

    console.log({ splitDepartmentName, joinValue: splitDepartmentName.join('_') });
    return splitDepartmentName.join('_');
  }
  return name;
};
export const changeNestedDepartmentToActual = (name?: string) => {
  if (name && name.includes('_')) {
    const splitDepartmentName = name.split('_');
    return splitDepartmentName.join('/');
  }
  return name;
};
export const isTodayInRange = (closedFrom: string, closedUntil: string): boolean => {
  if (!closedFrom && !closedUntil) {
    return false;
  }

  return dayjs().utc().isBetween(dayjs(closedFrom).utc(), dayjs(closedUntil).utc(), 'millisecond', '[]');
};
export const padTo2Digits = (number: any) => {
  return number.toString().padStart(2, '0');
};
export const checkIsDateValid = (date: Date | string | null | undefined) => {
  if (!date) {
    return false;
  }
};
export const utcFormatTime = (date: string) => {
  const time = new Date(date);

  return [padTo2Digits(time.getUTCHours()), padTo2Digits(time.getUTCMinutes())].join(':');
};
export const dateFormatter = (date: string | null | undefined | Date | any, format: string) => {
  switch (format) {
    case 'DD/MM/YYYY':
      return dayjs(date).format('DD/MM/YYYY');

    case 'YYYY-MM-DD':
      return dayjs(date).format('YYYY-MM-DD');

    case 'll':
      if (!checkIsDateValid(date)) {
        return date;
      }
      return dayjs(date ?? undefined).format('D MMM, YYYY');

    case 'hh:mm':
      return utcFormatTime(date);
    case 'MMMM yyyy':
      return dayjs(date).format('MMMM YYYY');

    case 'M':
    case 'D':
    case 'H':
      return dayjs.tz(date).format(format);

    default:
      return dayjs(date).format(format);
  }
};
export function isToday(date: string) {
  return dayjs(date).isSame(dayjs(), 'day');
}
